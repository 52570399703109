import { buildPagePath } from "../../../root/routing/urls"
import { Optional } from "../../../types/common"
import { CtaData } from "../../../ui/types"

export const toCtaData = (cta: any): Optional<CtaData> => {
  if (cta?.link?.href) {
    return {
      url: cta.link.href,
      label: cta.label,
    }
  }

  const url = cta?.link?.target ? buildPagePath(cta.link.target) : undefined
  if (url) {
    return {
      url,
      label: cta.label,
    }
  }
  return undefined
}
