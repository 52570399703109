import React from "react"
import ProductCarousel from "../../../../../ui/organisms/Product/ProductCarousel"
import ProductCarouselWall from "../../../../../ui/organisms/Product/ProductCarouselWall"
import { toProductCarouselProps } from "../../../converters/productCarousel"

interface Props {
  content: any
}

const ProductCarouselWrapper = ({ content }: Props) => {
  return content.item.productsCarousel.data.styles?.layout === "wall" ? (
    <ProductCarouselWall
      maxSlides={8}
      {...toProductCarouselProps(content.item.productsCarousel)}
    />
  ) : (
    <ProductCarousel
      maxSlides={8}
      {...toProductCarouselProps(content.item.productsCarousel)}
    />
  )
}

export default ProductCarouselWrapper
