import {
  ProductCarouselStyles,
  ProductCarouselWallProps,
} from "../../../ui/organisms/Product/ProductCarouselWall"
import { toCtaData } from "./cta"

export const toProductCarouselStyles = (item: any): ProductCarouselStyles => ({
  backgroundFilled: item.data.styles?.backgroundFilled,
  backgroundColor: item.data.styles?.backgroundColor?.hex,
  backgroundColorContrast: item.data.styles?.backgroundColorContrast?.hex,
  textColor: item.data.styles?.textColor?.hex,
  bannerRatio: item.data.styles?.bannerRatio,
  bannerRatioMobile: item.data.styles?.bannerRatioMobile,
})

export const toProductCarouselProps = (
  item: any
): ProductCarouselWallProps => ({
  products: item.availability?.results,
  title: item.data.contents.title,
  claim: item.data.contents.claim,
  banner: item.data.contents.banner,
  bannerMobile: item.data.contents.bannerMobile,
  bannerSeoTitle: item.data.contents.bannerSeoTitle,
  cta: toCtaData(item.data.contents.cta),
  ctaType: "slide",
  styles: toProductCarouselStyles(item),
  ...(item.data.contents.maxProducts
    ? { maxSlides: item.data.contents.maxProducts }
    : undefined),
})
